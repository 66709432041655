import { Image } from '@royalaholddelhaize/design-system-pantry-web/components/image/image';
import { ProductCardHorizontal } from '@royalaholddelhaize/design-system-pantry-web/components/product-card/templates/product-card-horizontal-templates';
import {
    ProductCardStyle,
    ProductCardVariant,
} from '@royalaholddelhaize/design-system-pantry-web/components/product-card/templates/product-card-templates.interfaces';
import classNames from 'clsx';
import { type FC, useRef } from 'react';
import {
    ProductImageAngle,
    type ProductSuggestionFragment,
} from '../../../../../graphql/_generated-operation-types';

import {
    type ComponentMeta,
    emitClickComponent,
    emitSelectProducts,
    productBatchService,
} from '@royalaholddelhaize/ah-analytics';
import { useInView } from '@royalaholddelhaize/design-system-pantry-web/hooks/use-in-view/use-in-view';
import css from './product.module.scss';

interface ProductProps {
    product: ProductSuggestionFragment['product'];
    isSelection?: boolean;
    onSwap: () => void;

    quantity: number;
    onIncrease?: () => void;
    onDecrease?: () => void;
    onUpdate?: (quantity: number) => void;
    index?: number;
}

export const Product: FC<ProductProps> = ({
    product,
    quantity,
    isSelection = false,
    onSwap,
    onIncrease = () => null,
    onDecrease = () => null,
    onUpdate = () => null,
    index,
}) => {
    const ref = useRef<HTMLDivElement>(null);

    useInView({
        ref,
        once: true,
        callback: isInView => {
            if (isInView) {
                productBatchService.load({
                    id: product?.id,
                    index,
                    listName: 'allerhande',
                });
            }
        },
    });

    if (!product) {
        return null;
    }

    const { title, webPath, imagePack, priceV2, availability, salesUnitSize } =
        product;

    const handleOnClick = () => {
        emitSelectProducts([
            {
                id: product.id,
                index: index ?? 0,
                listName: 'allerhande',
            },
        ]);

        if (webPath) {
            window.open(webPath, '_blank')?.focus();
        }
    };

    const handleOnSwap = () => {
        let cidComponent: ComponentMeta = {
            componentType: 'Regular button',
            componentSubType: 'button',
            componentTitle: 'ProductSuggestion',
            componentSection: 'Allerhande - Recipes',
        };

        if (isSelection) {
            cidComponent = {
                ...cidComponent,
                componentInnerText: 'kies',
                componentAdditional: `wi${product.id}`,
            };
        } else {
            cidComponent = {
                ...cidComponent,
                componentInnerText: 'wissel',
                componentAdditional: product.title || '',
            };
        }

        emitClickComponent(cidComponent);

        onSwap();
    };

    const isBonus =
        !!priceV2?.discount?.theme || !!priceV2?.promotionShields?.[0]?.theme;
    const priceInCents = Math.round((priceV2?.now?.amount || 0) * 100);
    const originalPriceInCents = Math.round((priceV2?.was?.amount || 0) * 100);

    return (
        <ProductCardHorizontal
            ref={ref}
            title={title || ''}
            priceInCents={priceInCents}
            originalPriceInCents={
                priceV2?.discount?.wasPriceVisible &&
                originalPriceInCents !== priceInCents
                    ? originalPriceInCents
                    : undefined
            }
            priceDescription={salesUnitSize || ''}
            discountPeriodText={priceV2?.discount?.description || ''}
            availabilityText={availability?.availabilityLabel || ''}
            quantity={quantity}
            onIncrease={onIncrease}
            onDecrease={onDecrease}
            onUpdate={onUpdate}
            onRemove={onDecrease}
            onClick={handleOnClick}
            onClickSwap={handleOnSwap}
            productCardVariant={
                isSelection
                    ? ProductCardVariant.ACTION
                    : ProductCardVariant.DEFAULT
            }
            chooseButtonText="kies"
            onClickChoose={onSwap}
            priceClassName={classNames({
                [css.priceDisabled]: !isSelection && quantity === 0,
            })}
            defaultAriaProps={{}}
            seoAnchorAttributes={{ href: webPath || '#' }}
            productCardStyle={
                isBonus ? ProductCardStyle.DISCOUNT : ProductCardStyle.REGULAR
            }
        >
            <Image
                src={
                    imagePack?.find(e =>
                        [
                            ProductImageAngle.ANGLE_2D1,
                            ProductImageAngle.HERO,
                        ].includes(e.angle),
                    )?.small?.url || ''
                }
                alt=""
            />
        </ProductCardHorizontal>
    );
};
