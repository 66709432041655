import { useRecipeShoppableSaveSelectionMutation } from '../_generated-hooks';
import {
    type RecipeProductSuggestionsFragment,
    type RecipeShoppableSelection,
    RecipeShoppableSelectionAction,
} from '../_generated-operation-types';

const mapProductSuggestion = ({
    ingredient,
    productSuggestion,
}: RecipeProductSuggestionsFragment): RecipeShoppableSelection | null => {
    if (!productSuggestion) {
        return null;
    }

    return {
        action: RecipeShoppableSelectionAction.ADD,
        ingredient: {
            id: ingredient.id,
            quantity: ingredient.quantityFloat ?? 0,
            name: {
                singular: ingredient.name || '',
                plural: ingredient.name || '',
            },
            quantityUnit: {
                singular: ingredient.quantityUnit || '',
                plural: ingredient.quantityUnit || '',
            },
        },
        product: {
            id: productSuggestion.id,
            quantity: productSuggestion.quantity,
            proposer: productSuggestion.proposer,
        },
    };
};

const normalizeProductSuggestionsToSelections = (
    productSuggestions: RecipeProductSuggestionsFragment[],
): RecipeShoppableSelection[] =>
    productSuggestions.map(mapProductSuggestion).filter(e => e !== null);

export const useMemberProductSelection = () => {
    const [saveIngredients] = useRecipeShoppableSaveSelectionMutation();

    const saveMemberProductSelection = ({
        recipeId,
        numberOfServings,
        ingredients,
    }: {
        recipeId: number | null;
        numberOfServings: number;
        ingredients: RecipeProductSuggestionsFragment[];
    }) => {
        const selections = normalizeProductSuggestionsToSelections(ingredients);

        saveIngredients({
            variables: {
                params: {
                    recipeId: recipeId || 0,
                    servingNumber: numberOfServings,
                    selections,
                },
            },
        }).catch();
    };

    return {
        saveMemberProductSelection,
    };
};
