import { emitSelectProducts } from '@royalaholddelhaize/ah-analytics';
import type { Locale } from '@royalaholddelhaize/ah-web-core';
import { useState } from 'react';
import type {
    IngredientToOverride,
    RecipeDetailsServing,
} from '../../../graphql/_generated-graphql-types';
import { useRecipeProductSuggestionsQuery } from '../../../graphql/_generated-hooks';
import {
    MutationResultStatus,
    type ProductSuggestionFragment,
    type RecipeProductSuggestionsFragment,
} from '../../../graphql/_generated-operation-types';
import { useAddToBasket } from '../../../graphql/basket/add-to-basket';
import { useMemberProductSelection } from '../../../graphql/recipe-product-suggestions/recipe-product-suggestions-save-selection-hook';

export enum ProductSuggestionsState {
    PRODUCT_SUGGESTIONS,
    ALTERNATIVES,
    VAGUE_TERMS,
    ADD_TO_BASKET_SUCCESS,
    ADD_TO_BASKET_FAILURE,
}

export interface UseRecipeProductSuggestionsModal {
    locale?: Locale.nl_NL | Locale.nl_BE | Locale.en_US;
    recipeId: number;
    initialServings: RecipeDetailsServing;
    member: {
        isRegistered: boolean;
    };
}

export interface ProductSuggestion extends RecipeProductSuggestionsFragment {
    isEnabled: boolean;
}

export const useRecipeProductSuggestionsModal = ({
    recipeId,
    initialServings,
    member,
}: UseRecipeProductSuggestionsModal) => {
    const [excludedIngredients, setExcludedIngredients] = useState<number[]>(
        [],
    );
    const [servings, setServings] = useState(initialServings);
    const [isModalOpen, setIsModalOpen] = useState(false);

    const [ingredientId, setIngredientId] = useState<number | null>(null);
    const [suggestions, setSuggestions] = useState<ProductSuggestion[]>([]);
    const [ingredientsToOverride, setIngredientsToOverride] = useState<
        IngredientToOverride[]
    >([]);
    const [quantityOverride, setQuantityOverride] = useState<
        Map<number, number>
    >(new Map());

    const [state, setState] = useState<ProductSuggestionsState>(
        ProductSuggestionsState.PRODUCT_SUGGESTIONS,
    );

    const { loading } = useRecipeProductSuggestionsQuery({
        skip: !recipeId,
        variables: {
            options: {
                recipeId: recipeId || 0,
                numberOfServings: servings.number,
                productIdOverride: [],
                ingredientsToOverride,
            },
        },
        errorPolicy: 'ignore',
        fetchPolicy: 'no-cache',
        onCompleted: data => {
            if (data?.recipeProductSuggestionsV2) {
                setSuggestions(
                    data.recipeProductSuggestionsV2.map(
                        ({
                            productSuggestion,
                            ingredient,
                            alternativeSections,
                            optional,
                            ...rest
                        }) => {
                            // Workaround for https://jira-eu-aholddelhaize.atlassian.net/browse/MILC-102959
                            const preferredProductId = optional
                                ? ingredientsToOverride.find(
                                      e => e.id === ingredient.id,
                                  )?.productOverrideId
                                : undefined;

                            if (preferredProductId && !productSuggestion) {
                                productSuggestion = alternativeSections
                                    .flatMap(e => e.productSuggestions)
                                    .find(
                                        e =>
                                            e?.product?.id ===
                                            preferredProductId,
                                    );
                            }
                            // -----

                            return {
                                ...rest,
                                ingredient,
                                productSuggestion: productSuggestion
                                    ? {
                                          ...productSuggestion,
                                          quantity:
                                              quantityOverride.get(
                                                  ingredient.id || 0,
                                              ) || productSuggestion?.quantity,
                                      }
                                    : undefined,
                                // Workaround for https://jira-eu-aholddelhaize.atlassian.net/browse/MILC-102959
                                alternativeSections: preferredProductId
                                    ? alternativeSections
                                          .map(
                                              ({
                                                  productSuggestions,
                                                  ...rest
                                              }) => ({
                                                  ...rest,
                                                  productSuggestions:
                                                      productSuggestions.filter(
                                                          e =>
                                                              e?.product?.id !==
                                                              preferredProductId,
                                                      ),
                                              }),
                                          )
                                          .filter(
                                              e =>
                                                  e.productSuggestions.length >
                                                  0,
                                          )
                                    : alternativeSections,
                                // -----
                                optional,
                                isEnabled: true,
                            };
                        },
                    ),
                );

                setIngredientId(null);
            }
        },
    });

    const { saveMemberProductSelection } = useMemberProductSelection();
    const { addBasketItems } = useAddToBasket({
        member,
        onComplete: data => {
            if (data.basketItemsAdd.status === MutationResultStatus.SUCCESS) {
                setState(ProductSuggestionsState.ADD_TO_BASKET_SUCCESS);
                saveMemberProductSelection({
                    recipeId,
                    numberOfServings: servings.number,
                    ingredients: suggestions,
                });
            }

            if (data.basketItemsAdd.status === MutationResultStatus.FAILED) {
                setState(ProductSuggestionsState.ADD_TO_BASKET_FAILURE);
            }
        },
        onError: () => {
            setState(ProductSuggestionsState.ADD_TO_BASKET_FAILURE);
        },
    });

    const handleOnAddToBasket = () => {
        const normalizedItems = suggestions
            .filter(
                suggestion =>
                    suggestion.isEnabled && suggestion.productSuggestion,
            )
            .map(suggestion => ({
                id: suggestion.productSuggestion?.id || 0,
                description: undefined,
                quantity: suggestion.productSuggestion?.quantity || 0,
            }));

        addBasketItems(normalizedItems);
    };

    const handleOnOpenAlternatives = (ingredientId: number) => {
        setState(ProductSuggestionsState.ALTERNATIVES);
        setIngredientId(ingredientId);
    };

    const handleOnSelectAlternative = (
        productSuggestion: ProductSuggestionFragment,
        index: number,
    ) => {
        if (!productSuggestion?.product || !ingredientId) return;

        setState(ProductSuggestionsState.PRODUCT_SUGGESTIONS);

        const selectedProductSuggestion = suggestions?.find(
            e => e.ingredient.id === ingredientId,
        );

        emitSelectProducts([
            {
                id: productSuggestion.product?.id || 0,
                index,
                listName: 'shoppable_recipe_alternative',
                parentProductId:
                    selectedProductSuggestion?.productSuggestion?.product?.id,
            },
        ]);

        setIngredientsToOverride(prev => [
            ...prev.filter(e => e.id !== ingredientId),
            {
                id: ingredientId,
                name: selectedProductSuggestion?.ingredient?.name || '',
                // biome-ignore lint/style/noNonNullAssertion: <explanation>
                productOverrideId: productSuggestion.product!.id,
            },
        ]);

        setQuantityOverride(prev => {
            prev.delete(ingredientId);
            return prev;
        });
    };

    const handleOnOpenVagueTerms = (ingredientId: number) => {
        setState(ProductSuggestionsState.VAGUE_TERMS);
        setIngredientId(ingredientId);
    };

    const handleOnSelectVagueTerm = (productId: number, index: number) => {
        if (!productId || !ingredientId) return;

        setState(ProductSuggestionsState.PRODUCT_SUGGESTIONS);

        emitSelectProducts([
            {
                id: productId,
                index,
                listName: 'shoppable_recipe_alternative',
            },
        ]);

        const ingredient = suggestions.find(
            e => e.ingredient.id === ingredientId,
        )?.ingredient;

        setIngredientsToOverride(prev => [
            ...prev.filter(e => e.id !== ingredientId),
            {
                id: ingredientId,
                name: ingredient?.name || '',
                productOverrideId: productId,
            },
        ]);

        setQuantityOverride(prev => {
            prev.delete(ingredientId);
            return prev;
        });
    };

    const handleOnIncreaseProductQuantity = (ingredientId: number) => {
        const selectedProductSuggestion = suggestions?.find(
            e => e.ingredient.id === ingredientId,
        );

        if (selectedProductSuggestion?.productSuggestion) {
            handleOnSetProductQuantity(
                ingredientId,
                selectedProductSuggestion.productSuggestion.quantity + 1,
            );
        }
    };

    const handleOnDecreaseProductQuantity = (ingredientId: number) => {
        const selectedProductSuggestion = suggestions?.find(
            e => e.ingredient.id === ingredientId,
        );

        if (selectedProductSuggestion?.productSuggestion) {
            handleOnSetProductQuantity(
                ingredientId,
                selectedProductSuggestion.productSuggestion.quantity - 1,
            );
        }
    };

    const handleOnSetProductQuantity = (
        ingredientId: number,
        quantity: number,
    ) => {
        setSuggestions(prev => {
            const clone = [...prev];
            const selectedProductSuggestion = clone?.find(
                e => e.ingredient.id === ingredientId,
            );

            if (selectedProductSuggestion?.productSuggestion) {
                const quantityClamp = Math.min(99, Math.max(0, quantity));

                selectedProductSuggestion.productSuggestion = {
                    ...selectedProductSuggestion.productSuggestion,
                    quantity: quantityClamp,
                };
            }

            return clone;
        });

        setQuantityOverride(prev => {
            prev.set(ingredientId, quantity);
            return prev;
        });
    };

    const handleOnServingsChange = (newServings: number) => {
        setServings(prev => ({
            ...prev,
            number: newServings,
        }));
    };

    const handleOnExcludeIngredients = (ingredientIds: number[]) => {
        setExcludedIngredients(ingredientIds);
    };

    const handleOnOpen = () => {
        setState(ProductSuggestionsState.PRODUCT_SUGGESTIONS);
        setIngredientId(null);
        setIsModalOpen(true);
    };

    const handleOnClose = () => {
        setIsModalOpen(false);
    };

    return {
        state,
        setState,

        isModalOpen,
        handleOnOpen,
        handleOnClose,

        isLoading: loading,

        servings,
        handleOnServingsChange,

        excludedIngredients,
        handleOnExcludeIngredients,

        suggestions,

        ingredientId,

        handleOnOpenAlternatives,
        handleOnSelectAlternative,

        handleOnOpenVagueTerms,
        handleOnSelectVagueTerm,

        handleOnIncreaseProductQuantity,
        handleOnDecreaseProductQuantity,
        handleOnSetProductQuantity,

        handleOnAddToBasket,
    };
};
